<template>
  <div>
    <heading-separator :title="title" :div="div"></heading-separator>
  </div>
</template>

<script>
import HeadingSeparator from "./HeadingSeparator.vue";

export default {
  name: "OneViewHeading",
  components: { HeadingSeparator },
  data() {
    return {
      title: "OneView - Consolidate, Simplify, Optimize",
      div: "<div> <br><p class='white--text'> OneView consolidates all the custom in-house apps, PowerBI reports, power apps etc. and gives you a single source of truth for all your applications and access mechanism. Ensure seamless employee access management and save licensing costs by integrating third party applications such as PowerBI.</p></div>",
    };
  },
};
</script>
